import { IEnvironment } from "src/interface";


export const environment: IEnvironment = {
  production: false,
  name: 'development',
  api: {
    url:'https://dev.cockpit.bridgestoneth.com',
    // tirewarranty:'https://app.tirewarranty.bridgestoneth.com' //FIXME Production
    tirewarranty:'https://dev.tyrewarranty.bridgestoneth.com' //Dev
  },
};
